import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Button } from '@mui/material'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../../internals/components/Copyright';
import ChartUserByCountry from '../ChartUserByCountry';
import CustomizedTreeView from '../CustomizedTreeView';
import CustomizedDataGrid from '../CustomizedDataGrid';
import HighlightedCard from '../HighlightedCard';
import PageViewsBarChart from '../PageViewsBarChart';
import SessionsChart from '../SessionsChart';
import StatCard, { StatCardProps } from '../StatCard';
import AuthContext from '../../context/AuthContext'
import axios from 'axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Loading from '../Loading'
import { PatreonResponse } from '../../types';

export default function HomePage() {
  const authContext = useContext(AuthContext);
  const [campaignDetails, setCampaignDetails] = useState({} as any);
  const [tiers, setTiers] = useState([] as any[]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!authContext.campaignDetails.data) {
      setLoading(true)
      return;
    }
    setCampaignDetails({
      name: authContext.campaignDetails?.data?.attributes?.creation_name
    });
    setTiers(
      authContext.campaignDetails.included.reduce((s: any, i: any) => {
        const { id, attributes, type } = i
        if(type === 'tier') {
          s.push({
            id,
            ...attributes
          })
        }
        return s
      }, [])
    );
    setLoading(false)

  }, [authContext.campaignDetails]);

  useEffect(() => {
    console.log(campaignDetails)
  }, [campaignDetails]);

  function formatCurrency(cents: number = 0): string {
    const dollars = (cents / 100).toFixed(2); // Convert cents to dollars and format to 2 decimal places
    if (cents === 0) return 'Free'
    return `$${dollars}`; // Prepend the dollar sign
  }

  const columns: GridColDef<(typeof tiers)[number]>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1
      // width: 90 
    },
    {
      field: 'title',
      headerName: 'Tier Name',
      flex: 1
      // width: 300,
    },
    // {
    //   field: 'description',
    //   headerName: 'Tier Description',
    //   type: 'number',
    //   width: 400,
    //   editable: true,
    // },
    {
      field: 'amount_cents',
      headerName: 'Cost',
      valueFormatter: (formatCurrency),
      flex: 1
      // width: 150,
    },
  ];

  console.log('authContext.user', authContext?.user?.email)
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Tiers
      </Typography>
      {loading && <Loading message='Loading tiers from Patreon...' />}
      {!loading && <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ xs: 12, sm: 12, lg: 12 }}>
          {authContext?.user?.email && <DataGrid
            rows={tiers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />}
        </Grid>
      </Grid>}
    </Box>
  );
}
