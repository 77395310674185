import { createContext } from 'react';
export enum SiteModes {
  creator = 'creator',
  patron = 'patron'
}
interface SiteMode {
  siteMode: SiteModes;
  setCreatorMode: Function;
  setPatronMode: Function;
}


const ModeContext = createContext({} as SiteMode);

export default ModeContext;