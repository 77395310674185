import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuButton from './MenuButton';
import { useAuth0 } from "@auth0/auth0-react";
import ModeContext from '../context/ModeContext'
const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenu() {
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()
  const modeContext = useContext(ModeContext)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent' }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        <MenuItem onClick={() => {
          modeContext.setCreatorMode()
          navigate('/home')
        }}>
          {modeContext.siteMode === 'creator' && <CheckBox />}
          {modeContext.siteMode !== 'creator' && <CheckBoxOutlineBlank />}
          Creator Mode
        </MenuItem>
        <MenuItem onClick={() => {
          modeContext.setPatronMode()
          navigate('/home')
        }}>
          {modeContext.siteMode === 'patron' && <CheckBox />}
          {modeContext.siteMode !== 'patron' && <CheckBoxOutlineBlank />}
          Patron Mode
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>Add another account</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem> */}
        <Divider />
        {isAuthenticated && <MenuItem
          onClick={() => {
            handleClose()
            logout({ logoutParams: { returnTo: window.location.origin } })
          }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: 'auto',
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>}
        {!isAuthenticated && <MenuItem
          onClick={() => {
            handleClose()
            loginWithRedirect()
          }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: 'auto',
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Login</ListItemText>
          <ListItemIcon>
            <LoginRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>}
      </Menu>
    </React.Fragment>
  );
}
