import * as React from 'react';
import { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InsertDriveFileRounded from '@mui/icons-material/InsertDriveFileRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useLocation } from 'react-router-dom'
import ModeContext from '../context/ModeContext'

// const secondaryListItems = [
//   // { text: 'Settings', icon: <SettingsRoundedIcon /> },
//   // { text: 'About', icon: <InfoRoundedIcon /> },
//   // { text: 'Feedback', icon: <HelpRoundedIcon /> },
// ];

export default function MenuContent() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const location = useLocation()

  const modeContext = useContext(ModeContext)
  const navigate = useNavigate();

  const mainListItems = [
    { path: '/home', text: 'Home', icon: <HomeRoundedIcon /> },
  ];
  if(modeContext.siteMode === 'creator') {
    mainListItems.push({ path: '/tiers', text: 'Tiers', icon: <BarChartRounded /> })
  }
  mainListItems.push({ path: '/files', text: 'Files', icon: <InsertDriveFileRounded /> })
  
  useEffect(() => {
    const item = mainListItems.find(f => f.path === location.pathname)
    if(item) {
      setSelectedIndex(mainListItems.indexOf(item))
    }
  }, [location])

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={() => {
            navigate(item.text.toLowerCase());
            setSelectedIndex(index);
          }}>
            <ListItemButton selected={selectedIndex === index}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Stack>
  );
}
