import React, { useEffect, createContext, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import AuthContext from './context/AuthContext';
import Loading from './components/Loading'
import axios from 'axios'
import { CampaignDetails, PatreonResponse } from './types';
import ModeContext, { SiteModes } from './context/ModeContext';

function App() {

  const [token, setToken] = React.useState('');
  const [_user, setUser] = React.useState('' as any);
  const [loading, setLoading] = React.useState(true);
  const [campaignDetails, setCampaignDetails] = React.useState({} as CampaignDetails);
  const [siteMode, setSiteMode] = React.useState(SiteModes.patron);

  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const [userMetadata, setUserMetadata] = React.useState(null);

  useEffect(() => {

    const getUserMetadata = async () => {
      setLoading(true)
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://sbx.api.backerz.one',
            scope: "profile",
          },
        });
        setToken(accessToken);
        // if (user) {
        //   setUser(user)
        // }
      } catch (e: any) {
        console.log(e.message);
      } finally {
        setLoading(false)
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    console.log(_user)
  }, [_user]);

  useEffect(() => {
    if (!token) {
      return;
    }
    (async () => {
      const result: any = await axios.get('https://sbx.api.backerz.one/v0.1/my-campaigns', {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Api-Key': 'kna793h790n5v26cbhjalof08cn03b77cv619nxg',
        },
      });
      const data: PatreonResponse[] = result.data
      setCampaignDetails(data[0]?.details);

    })();
  }, [token]);

  const setCreatorMode = () => {
    setSiteMode(SiteModes.creator)
  }

  const setPatronMode = () => {
    setSiteMode(SiteModes.patron)
  }

  return <AuthContext.Provider value={{
    user, //: _user,
    token,
    campaignDetails
  }}>
    <ModeContext.Provider value={{
      siteMode,
      setCreatorMode,
      setPatronMode,
    }}>
      {loading && <Loading message="Loading..." />}
      <Dashboard />
    </ModeContext.Provider>
  </AuthContext.Provider>
}

export default App;
