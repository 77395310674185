import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlotProps,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import AuthContext from '../context/AuthContext';
import { CloudUpload, Download } from '@mui/icons-material';
import axios from 'axios'
import { putFile, deleteFile, getUploadUrl, uploadToS3PresignedUrl, getDownloadUrl } from '../service';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

interface FileRow {
  id: string
  title: string
  tiers: string[]
  file: {
    content?: string
    name?: string
    size?: string
    type?: string
    key?: string
  }
  isNew?: boolean
}

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    setRows: (newRows: (oldRows: FileRow[]) => FileRow[]) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }
}

function EditToolbar(props: GridSlotProps['toolbar']) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, title: '', tiers: [], file: {}, isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add File
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {

  const authContext = React.useContext(AuthContext);
  const [rows, setRows] = React.useState([] as FileRow[]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [tiers, setTiers] = React.useState([])
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)
  const [deleteRowInFocus, setDeleteRowInFocus] = React.useState({} as any)

  React.useEffect(() => {
    if (!authContext.token || rows.length) return;

    (async () => {
      const { data } = await axios.get('https://sbx.api.backerz.one/v0.1/files', {
        headers: {
          Authorization: `Bearer ${authContext.token}`,
          'X-Api-Key': 'kna793h790n5v26cbhjalof08cn03b77cv619nxg',
        },
      });
      const mapped = data.files.map((f: any) => {
        f.isNew = false
        return f
      })
      setRows(mapped as any)

    })();
  }, [authContext.token, rows.length]);

  React.useEffect(() => {
    const _tiers = authContext.campaignDetails?.included?.reduce((s: any, i: any) => {
      if (i.type === 'tier') {
        s.push(i.attributes.title)
      }
      return s
    }, [])
    setTiers(_tiers)
  }, [authContext.campaignDetails])

  const CustomTiersEditCell = (props: GridRenderEditCellParams) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef<HTMLInputElement | null>(null);

    React.useLayoutEffect(() => {
      if (hasFocus && ref.current) {
        ref.current.focus();
      }
    }, [hasFocus]);

    const handleValueChange = (event: any) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <FormControl fullWidth>
      <Select
        id="tiers"
        sx={{ margin: '2px', padding: '2px' }}
        // size='small'
        value={value || []}
        label="Age"
        multiple
        onChange={handleValueChange}
      >
        {tiers.map(t => <MenuItem value={t}>{t}</MenuItem>)}
      </Select>
    </FormControl>
  }

  const handleDownload = () => { }
  const renderFileDetails = (file: any) => {
    const boxStyle: any = {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
    if (file) {
      return (
        <Box style={{ ...boxStyle, flexDirection: 'column' }}>
          <Typography variant="body2">{file.name}</Typography>
          <Typography variant="caption" color="textSecondary">
            {`${file.type} - ${(file.size / 1024).toFixed(2)} KB`}
          </Typography>
        </Box>
      );
    }
    return (
      <Box style={{ ...boxStyle, flexDirection: 'row' }}>
        <CloudUpload style={{ marginRight: 8 }} />
        <Typography variant="body2">Choose file</Typography>
      </Box>
    );
  };

  const CustomFileEditCell: React.FC<any> = (props: GridRenderEditCellParams) => {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const [file, setFile] = React.useState<File | null>(value ? (value as File) : null);
    const [fileContent, setFileContent] = React.useState<string | ArrayBuffer | null>(null); // New state to hold file content
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useLayoutEffect(() => {
      if (hasFocus && inputRef.current) {
        inputRef.current?.focus();
      }
    }, [hasFocus]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files ? event.target.files[0] : null;
      if (selectedFile) {
        setFile(selectedFile);

        // Read file content
        const reader = new FileReader();
        reader.onload = () => {
          setFileContent(reader.result); // Store the file content
          apiRef.current.setEditCellValue({
            id, field, value: {
              name: selectedFile.name,
              size: selectedFile.size,
              type: selectedFile.type,
              content: reader.result
            }
          }); // Save file and content
        };
        reader.readAsDataURL(selectedFile); // You can change this to `readAsText` or other methods as needed
      }
    };

    const handleButtonClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    return (
      <FormControl fullWidth>
        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button onClick={handleButtonClick} variant="outlined" fullWidth>
          {renderFileDetails(file)}
        </Button>
      </FormControl>
    );
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (event: any) => {
    const { row } = event
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });
  };

  const handleDownloadFile = async (event: any) => {
    const { row } = event
    
    if (!row.file?.key) {
      console.error('No file key available for download.');
      return;
    }

    try {
      // Step 1: Get the presigned URL from your backend
      const { url, key } = await getDownloadUrl(row.file.key, authContext.token)

      // Step 2: Fetch the file using the presigned URL
      const response = await axios.get(url, {
        responseType: 'blob', // Ensure the file is downloaded as binary data
      });

      // Step 3: Create a download link
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = row.file?.name || 'download';
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleDeleteClick = (event: any) => () => {
    const { row } = event
    setDeleteRowInFocus(row)
    setConfirmDeleteDialogOpen(true)
  };

  const onConfirmDeleteFile = async () => {
    const result = await deleteFile(deleteRowInFocus, authContext.token)
    setRows(rows.filter((_row) => _row.id !== deleteRowInFocus?.id));
    setConfirmDeleteDialogOpen(false)
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: FileRow = rows.find((row: FileRow) => row.id === id) || {} as FileRow;
    if (editedRow.isNew) {
      setRows(rows.filter((row: FileRow) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: FileRow): Promise<FileRow> => {
    const updatedRow: FileRow = { ...newRow, isNew: false };
    // const presignedUrl = await 
    const _rows: FileRow[] = structuredClone(rows)
    const __rows: FileRow[] = _rows.map((row: FileRow) => (row.id === newRow.id ? updatedRow : row)) || []
    setRows(__rows);
    const { key, url } = await getUploadUrl(authContext.token)
    newRow.file.key = key
    const uploadRes = await uploadToS3PresignedUrl(url, newRow.file)
    putFile(newRow, authContext.token)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', width: 180, editable: true },
    {
      field: 'tiers',
      headerName: 'Tiers',
      type: 'custom',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: props => <CustomTiersEditCell {...props} />,
    },
    {
      field: 'file',
      headerName: 'File',
      type: 'custom',
      flex: 1,
      editable: true,
      renderCell: (params: any) => {
        return renderFileDetails(params?.row?.file)
      },
      renderEditCell: props => <CustomFileEditCell {...props} />,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      getActions: (row) => {
        const { id } = row
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleSaveClick(row)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Download />}
            label="Download"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => handleDownloadFile(row)}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <ConfirmDeleteDialog 
        open={confirmDeleteDialogOpen}
        fileName={deleteRowInFocus.file?.name}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        onConfirm={() => onConfirmDeleteFile()}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: EditToolbar }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}
